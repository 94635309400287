import { gql, useQuery } from '@apollo/client'
import React, { useMemo, useState } from 'react'

import { useTitle } from '../../components/browser'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import RecordCreateButton from '../../components/record/create-button'
import Table from '../../components/table'
import tableFragment from '../../fragments/table'

const GET_RECIPES_QUERY = gql`
  query GetRecipes($search: String, $first: Int, $after: String) {
    records: allRecipes(first: $first, search: $search, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ${tableFragment}
        }
      }
    }
  }
`

const title = "Recipes"
const PER_PAGE = 28

export default function RecipeIndexPage() {
  useTitle(title)
  const [searchQuery, setSearchQuery] = useState()
  const { loading, error, data, fetchMore } = useQuery(GET_RECIPES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { search: searchQuery, first: PER_PAGE }
  })
  const records = useMemo(() => data?.records?.edges?.map(({ node }) => node), [data])

  if (error) {
    console.error(error)
    return <div>There was an error loading the recipes.</div>
  }

  return (
    <Layout>
      <LayoutCompass
        title={title}
        actions={(
          <RecordCreateButton
            title="Create Recipe"
            type="Recipe"
            redirectOnSuccessTo="/recipes/:id"
          />
        )}
      />
      <LayoutContent>
        <Table
          label="recipe"
          loading={loading}
          records={records}
          enableSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          to='/recipes'
          fetchMore={fetchMore}
          perPage={PER_PAGE}
          pageInfo={data?.records?.pageInfo}
        />
      </LayoutContent>
    </Layout>
  )
}
